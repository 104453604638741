<template>
  <div>
    <vs-popup class="sm:popup-w-80" title="Tambah Perijinan Warga" :active="isActive" v-on:update:active="emitModalIsActive">

      <div>
        <div class="vx-row">
          <div class="vx-col w-full">
            <ValidationErrors :errors="errors"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-4/12 w-full">
            <label class="ml-1 text-xs">No. Izin</label>
            <vs-input class="w-full" placeholder="Generate Otomatis" v-model="data.no_ijin" disabled/>
          </div>
          <div class="vx-col sm:w-4/12 w-full">
            <label class="ml-1 text-xs">Proyek *</label>
            <v-select :options="proyeks" label="nama" :reduce="item => item.id" :clearable="false" v-model="data.id_proyek"/>
          </div>
          <div class="vx-col sm:w-4/12 w-full">
            <label class="ml-1 text-xs">Properti Unit *</label>
            <v-select :options="propertiUnits" label="no_unit" :reduce="item => item.id" :clearable="false" v-model="data.id_properti_unit" @input="onChangePropertiUnit($event)"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-4/12 w-full">
            <label class="ml-1 text-xs">Item Izin Warga *</label>
            <div @click="modalItemIzin.active = true">
              <vx-input-group>
                <vs-input :value="data.nama_item_perijinan_warga" readonly/>
                <template slot="append">
                  <div class="append-text btn-addon">
                    <vs-button type="filled" icon-pack="feather" icon="icon-search"/>
                  </div>
                </template>
              </vx-input-group>
            </div>
          </div>
          <div class="vx-col sm:w-4/12 w-full">
            <label class="ml-1 text-xs">Tgl. Mulai *</label>
            <flat-pickr class="w-full" v-model="data.tgl_mulai"></flat-pickr>
          </div>
          <div class="vx-col sm:w-4/12 w-full">
            <label class="ml-1 text-xs">Tgl. Akhir *</label>
            <flat-pickr class="w-full" v-model="data.tgl_akhir"></flat-pickr>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-6/12 w-full">
            <label class="ml-1 text-xs">Atas Nama *</label>
            <vs-input class="w-full" icon-pack="feather" icon="icon-user" v-model="data.atas_nama"/>
          </div>
          <div class="vx-col sm:w-6/12 w-full">
            <label class="ml-1 text-xs">Telp *</label>
            <vs-input class="w-full" icon-pack="feather" icon="icon-phone" v-model="data.telp"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col w-full">
            <label class="ml-1 text-xs">Keterangan *</label>
            <vs-textarea class="w-full" v-model="data.keterangan"/>
          </div>
        </div>

        <div class="vx-row float-right mt-6">
          <div class="vx-col w-full">
            <vs-button class="mr-3" type="border" @click="emitModalIsActive(false)">Batal</vs-button>
            <vs-button :disabled="isLoading" @click="save">
              <span v-if="isLoading" class="animate-pulse">Menyimpan...</span>
              <span v-if="!isLoading">Simpan</span>
            </vs-button>
          </div>
        </div>
      </div>

      <!--modals-->
      <vs-popup class="sm:popup-w-70 popup-content-no-padding animate-none"
                title="Pilih Data Izin Warga"
                :active="modalItemIzin.active"
                v-on:update:active="modalItemIzin.active = $event">
        <ItemPerijinanWarga v-if="!isFirstActive" :selectable="true" @selected="onSelectedModalItemIjin"/>
      </vs-popup>

    </vs-popup>
  </div>
</template>

<script>
import PerijinanWargaRepository from '@/repositories/estate/perijinan-warga-repository'
import ProyekRepository from '@/repositories/master/proyek-repository'
import PropertiUnitRepository from '@/repositories/master/properti-unit-repository'
import ItemPerijinanWarga from '@/views/pages/master/item-perijinan-warga/ItemPerijinanWarga'
import ValidationErrors from '@/views/components/validation-errors/ValidationErrors'
import 'flatpickr/dist/flatpickr.css'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import moment from 'moment'
import _ from 'lodash'
import BookingRepository from '@/repositories/marketing/booking-repository'

export default {
  name: 'PerijinanWargaAdd',
  props: ['isActive'],
  components: {
    ItemPerijinanWarga,
    ValidationErrors,
    'v-select': vSelect,
    flatPickr
  },
  watch: {
    isActive (active) {
      if (active && this.isFirstActive) {
        this.getProyek()
        this.isFirstActive = false
      }
    },
    'data.id_proyek' (newVal, oldVal) {
      if (newVal && !_.isEqual(newVal, oldVal)) {
        this.onChangeProyek()
      }
    }
  },
  data () {
    return {
      isFirstActive: true,
      isLoading: false,
      errors: null,
      proyeks: [],
      propertiUnits: [],
      modalItemIzin: {
        active: false
      },
      data: {
        id_proyek: null,
        id_properti_unit: null,
        id_item_perijinan_warga: null,
        tgl_mulai: moment().format('YYYY-MM-DD'),
        atas_nama: null,
        telp: null
      }
    }
  },
  methods: {
    onChangePropertiUnit (idPropertiUnit) {
      this.getDetailCustomer(idPropertiUnit)
    },

    onChangeProyek () {
      this.propertiUnits = []
      this.data.id_properti_unit = null
      if (this.data.id_proyek) this.getPropertiUnit()
    },

    onSelectedModalItemIjin (item) {
      this.data.id_item_perijinan_warga = item.id
      this.data.nama_item_perijinan_warga = item.nama
      this.modalItemIzin.active = false
    },

    getDetailCustomer (idPropertiUnit) {
      const params = {
        page: 1,
        limit: 1,
        filter: JSON.stringify({
          id_properti_unit: idPropertiUnit,
          status_approval_skup: 'DITERIMA'
        })
      }
      BookingRepository.get(params)
        .then(response => {
          const data = response.data.data.data
          if (data.length > 0) {
            this.data.atas_nama = data[0].nama_customer
            this.data.telp = data[0].hp_customer
          }
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
    },

    getProyek () {
      const params = {
        filter: JSON.stringify({
          only_authorized_user: 1
        })
      }
      ProyekRepository.getAll(params)
        .then(response => {
          this.proyeks = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    getPropertiUnit () {
      const params = {
        filter: JSON.stringify({
          id_proyek: this.data.id_proyek
        })
      }
      PropertiUnitRepository.getAll(params)
        .then(response => {
          this.propertiUnits = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    save () {
      this.errors = null
      this.isLoading = true

      const params = this.buildParams()
      PerijinanWargaRepository.create(params)
        .then(response => {
          this.onSuccess()
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          } else {
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    buildParams () {
      return {
        id_item_perijinan_warga: this.data.id_item_perijinan_warga,
        id_properti_unit: this.data.id_properti_unit,
        tgl_mulai: this.data.tgl_mulai,
        tgl_akhir: this.data.tgl_akhir,
        atas_nama: this.data.atas_nama,
        telp: this.data.telp,
        keterangan: this.data.keterangan
      }
    },

    onSuccess () {
      this.notifySuccess('Data berhasil disimpan.')
      this.resetData()
      this.emitIsSuccess(true)
      this.emitModalIsActive(false)
    },

    resetData () {
      const exceptObjects = _.pick(this.$data, ['proyeks'])
      const newData = Object.assign(this.$options.data.call(this), exceptObjects)
      Object.assign(this.$data, newData)
    },

    emitIsSuccess (isSuccess) {
      this.$emit('success', isSuccess)
    },

    emitModalIsActive (isActive) {
      this.$emit('update:isActive', isActive)
    }
  }
}
</script>
